import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout title="Not Found">
    <div className="page-404">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... please check your dial.</p>
    </div>
  </Layout>
)

export default NotFoundPage
